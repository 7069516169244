import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"
import ImageDownloadSocial from "../../../components/ImageDownloadSocial"



const IndexPage = () => {
    const root = "/img/download/coffee-coworking"

    return (
        <Layout pageTitle="Coffee & Coworking - Templates">
            <Row>
                <Col>
                    <Link to="/events/coffee-coworking">Back</Link>
                </Col>
            </Row>
                
            <ImageDownload
            root ="/img/download/coffee-coworking"
            filename="coffee-coworking-feedback"
            name="Coffee &amp; Coworking Feedback Posts"
            text="Use these to share your feedback from events. You can add your own photos and feedback using this template"
            text2=""
            squareHref="https://www.canva.com/design/DAFpKZyeHLg/wuJJb3aVbwGcO__bPRNgMg/view?utm_content=DAFpKZyeHLg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFBy_dCGdI/30jKXEXG7docjmolkUxwKQ/view?utm_content=DAFBy_dCGdI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1ps3-EnwEbA7S1MKmSz2WpCuu6vjisCYVjZUjpWgI4wM/edit?usp=sharing"
            />  
           

            
        </Layout>

        
    )
}

export default IndexPage
